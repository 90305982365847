import { Row, Col } from "antd";
import React, { useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { MapContainer, TileLayer , Marker, Popup, useMapEvents} from "react-leaflet";
import { LatLngExpression } from "leaflet";
import { Button } from "../../common/Button";
//import "leaflet/dist/leaflet.css";
//import MapComponent from "../Map";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,

} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}
const initialMarkers = [
  /*   {
        position: {
            lat: -18.90357,
            lng: 47.52795
       },
        draggable: true

        }*/
    {
        position: {
            lat: -18.90387,
            lng: 47.52785
        },
        draggable: false
    },
   /* {
        position: {
            lat: -18.90387,
            lng: 47.52735
        },
        draggable: true
    },*/
];


const containerStyle = {
  width: "100%",
  height: "400px"
}
const icon ="lieu.jpg";  

const Map = () => {
  const defaultPosition: LatLngExpression = [-18.90377, 47.52785]; // Tsiazotafo position
  const [markers, setMarkers] = useState(initialMarkers);
  return (
    <div className="map__container">
      <MapContainer
        style={containerStyle}
        center={defaultPosition}
        zoom={20}
      >
        <TileLayer
          attribution='© OpenStreetMap contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {markers.map((marker, index) => (
                <MarkerContent
                    key={index}
                    position={marker.position}
                    draggable={marker.draggable}
                   // onMarkerClick={event => markerClicked(marker, index)}
                   // onDragEnd={event => markerDragEnd(event, index)}
                />
            ))}
      </MapContainer>
      </div>
  );
};

const MarkerContent = (props) => {
  const markerRef = useRef();
  const { position, draggable, onMarkerClick, onDragEnd } = props;
  const icon ="lieu.jpg";  
  
  return <Marker
      position={position}
      draggable={draggable}
      eventHandlers={{
          click: event => onMarkerClick(event),
         // dragend: () => onDragEnd(markerRef.current.getLatLng())
      }}
      ref={markerRef}
  >
      <Popup>
          <b>EPSI </b> <br/>Lot II D 55 Bis Tsiazotafo Ambodrona.<br/>
          <b>{position.lat}, {position.lng}</b><br/>
          <SvgIcon src={icon} width="200px" height="100%" />
          <br/>

      </Popup>
  </Marker>
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  
  return (
    <>
    
    <FooterSection>
        <Container>
          
          <Row justify="space-between">
            <Col lg={10} md={10} sm={24} xs={24}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Para>Lot IID 55 Bis</Para>
              <Para>Tsiazotafo Ambondrona</Para>
              <Para>Antananarivo - Madagascar</Para>
            </Col>
            <Col lg={14} md={14} sm={24} xs={24}>
            <div className="fb-page" data-href="https://www.facebook.com/61550636631049/" data-tabs="timeline" data-width="400" data-height="300" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/61550636631049/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/61550636631049/">Epsifana</a></blockquote></div>
            </Col>
            
          </Row>
        </Container>
      </FooterSection>
      <Extra>
      
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>

            <NavLink to="/fpq">
              <LogoContainer>
              <Button>{t("Certification")}</Button>
              </LogoContainer>
            </NavLink>
            
            <FooterContainer>
              
                  <a
                          href= "https://cours.epsi.mg/"
                          target="_blank"
                          rel="noopener noreferrer"
     
                    >
                         <Button>{t("Cours")}</Button>
                  </a>
                     
                 
                      
              
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
